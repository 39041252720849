var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
export var proxyGtfSearchParam = function (baseUrl) {
    var url = new URL(window.location.href);
    var params = new URLSearchParams(url.search);
    var gtfSearchParams = {};
    params.forEach(function (value, key) {
        if (key.startsWith("gtf") || key === "merchantId") {
            gtfSearchParams[key] = value;
            params.delete(key);
        }
    });
    url.search = params.toString();
    window.history.pushState({}, "", url.toString());
    return extendSearchParams(baseUrl, gtfSearchParams);
};
export var extendSearchParams = function (url, params) {
    if (typeof url !== "string")
        throw Error("Invalid url type, must be a string. Receive type ".concat(typeof url));
    if (typeof params !== "object")
        throw Error("Invalid params type, must be a string. Receive type ".concat(typeof params));
    var u = new URL(url);
    u.search = new URLSearchParams(__spreadArray(__spreadArray([], Array.from(new URLSearchParams(u.search).entries()), true), Object.entries(params), true)).toString();
    return u.toString();
};
export var isValidUrl = function (url) {
    try {
        var newUrl = new URL(url);
        return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
    }
    catch (err) {
        return false;
    }
};
