var PREFIX_MSG = "gatefi:";
var PMService = (function () {
    function PMService(target) {
        if (target === void 0) { target = window; }
        this.debug = false;
        this.subscribes = {};
        this.target = target;
    }
    PMService.prototype.send = function (type, payload) {
        if (this.debug)
            console.log("[GateFiSDK] send", type);
        this.target.postMessage({
            type: this.getTypeWithPrefix(type),
            payload: payload,
        }, "*");
    };
    PMService.prototype.start = function () {
        window.addEventListener("message", this.listenerMessage.bind(this));
    };
    PMService.prototype.stop = function () {
        window.removeEventListener("message", this.listenerMessage.bind(this));
    };
    PMService.prototype.subscribe = function (type, callback) {
        if (!type)
            return;
        if (!callback)
            return;
        if (!this.subscribes[type])
            this.subscribes[type] = [];
        var exist = this.subscribes[type].find(function (c) { return c === callback; });
        if (!exist)
            this.subscribes[type].push(callback);
    };
    PMService.prototype.unsubscribe = function (type, callback) {
        if (!this.subscribes[type])
            return;
        this.subscribes[type] = this.subscribes[type].filter(function (c) { return c !== callback; });
    };
    PMService.prototype.clear = function () {
        this.subscribes = {};
    };
    PMService.prototype.run = function (type, payload) {
        if (!this.subscribes[type])
            return;
        this.subscribes[type].forEach(function (callback) { return callback(type, payload); });
    };
    PMService.prototype.listenerMessage = function (e) {
        var data = e.data;
        if (!e.isTrusted)
            return;
        var message = data;
        if (!message ||
            typeof message !== "object" ||
            typeof message.type !== "string" ||
            !message.type.startsWith(PREFIX_MSG)) {
            return;
        }
        if (this.debug)
            console.log("[GateFiSDK] receive", e.data);
        var type = this.getType(message.type);
        this.run(type, message.payload);
    };
    PMService.prototype.getTypeWithPrefix = function (type) {
        return "".concat(PREFIX_MSG).concat(type);
    };
    PMService.prototype.getType = function (type) {
        return type.replace(PREFIX_MSG, "");
    };
    return PMService;
}());
export { PMService };
