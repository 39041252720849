var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var DOMControlService = (function () {
    function DOMControlService() {
        this.mediaQuery = "(max-width: 540px)";
    }
    DOMControlService.prototype.createWidget = function (baseUrl, styles) {
        if (styles === void 0) { styles = {}; }
        var iframe = document.createElement("iframe");
        iframe.setAttribute("src", baseUrl);
        Object.assign(iframe.style, __assign({ width: "420px", height: "680px", border: "none" }, styles));
        return iframe;
    };
    DOMControlService.prototype.isNodeExist = function (nodeSelector) {
        return !!document.querySelector(nodeSelector);
    };
    DOMControlService.prototype.addListenerOnClick = function (nodeSelector, callback) {
        var element = document.querySelector(nodeSelector);
        element.addEventListener("click", callback);
        return function () {
            element.removeEventListener("click", callback);
        };
    };
    DOMControlService.prototype.addListenerOnScreenSizeChangedToMobile = function (callback) {
        var windowMediaQueryList = window.matchMedia(this.mediaQuery);
        var handleOnChange = function (e) { return callback(e.matches); };
        windowMediaQueryList.addEventListener("change", handleOnChange);
        return function () {
            windowMediaQueryList.removeEventListener("change", handleOnChange);
        };
    };
    DOMControlService.prototype.isMobileScreen = function () {
        return window.matchMedia(this.mediaQuery).matches;
    };
    return DOMControlService;
}());
export var domControlService = new DOMControlService();
