import { domControlService } from "../services/DOMControlService";
export var OVERLAY_ID = "gatefi-sdk-overlay";
var INITIAL_WIDGET_STYLES = {
    width: "420px",
    height: "680px",
    "border-radius": "24px",
    "box-shadow": "0px 12px 24px rgba(6, 13, 51, 0.07)"
};
var MOBILE_WIDGET_STYLES = {
    width: "100%",
    height: "100%",
    "border-radius": "0px",
    "box-shadow": "none",
};
var INITIAL_WRAPP_STYLES = {
    height: "auto",
};
var MOBILE_WRAPP_STYLES = {
    height: "100%",
};
var OverlayLayout = (function () {
    function OverlayLayout() {
        this.widget = null;
        this.overlay = null;
        this.contentWrapp = null;
        this.eventListeners = [];
    }
    OverlayLayout.prototype.create = function (baseUrl, nodeSelector) {
        this.overlay = this.createOverlay();
        this.contentWrapp = this.createWrapp();
        this.widget = domControlService.createWidget(baseUrl, domControlService.isMobileScreen() ? MOBILE_WIDGET_STYLES : INITIAL_WIDGET_STYLES);
        this.overlay.appendChild(this.contentWrapp);
        this.contentWrapp.appendChild(this.widget);
        document.body.appendChild(this.overlay);
        if (nodeSelector) {
            this.eventListeners.push(domControlService.addListenerOnClick(nodeSelector, this.show.bind(this)));
        }
        this.eventListeners.push(domControlService.addListenerOnScreenSizeChangedToMobile(this.onMobileView.bind(this)));
        this.eventListeners.push(domControlService.addListenerOnClick("#".concat(OVERLAY_ID), this.hide.bind(this)));
    };
    OverlayLayout.prototype.createOverlay = function () {
        var overlay = document.createElement("div");
        Object.assign(overlay.style, {
            position: "fixed",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            right: "0",
            bottom: "0",
            "background-color": "rgba(0,0,0,0.5)",
            "backdrop-filter": "blur(12px)",
            "z-index": "2",
            filter: "blur(0)",
            overflowY: "auto",
            zIndex: 1000,
        });
        overlay.setAttribute("id", OVERLAY_ID);
        return overlay;
    };
    OverlayLayout.prototype.createWrapp = function () {
        var wrapp = document.createElement("div");
        Object.assign(wrapp.style, {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100%",
        });
        return wrapp;
    };
    OverlayLayout.prototype.onMobileView = function (isMobile) {
        Object.assign(this.widget.style, isMobile ? MOBILE_WIDGET_STYLES : INITIAL_WIDGET_STYLES);
        Object.assign(this.contentWrapp.style, isMobile ? MOBILE_WRAPP_STYLES : INITIAL_WRAPP_STYLES);
    };
    OverlayLayout.prototype.getContentWindow = function () {
        var _a;
        return ((_a = this.widget) === null || _a === void 0 ? void 0 : _a.contentWindow) || null;
    };
    OverlayLayout.prototype.destroy = function () {
        if (!this.widget || !this.overlay)
            return;
        this.eventListeners.forEach(function (callback) { return callback(); });
        this.overlay.remove();
        this.overlay = null;
        this.widget = null;
    };
    OverlayLayout.prototype.show = function () {
        if (!this.overlay)
            return;
        this.overlay.style.display = "block";
    };
    OverlayLayout.prototype.hide = function () {
        if (!this.overlay)
            return;
        this.overlay.style.display = "none";
    };
    return OverlayLayout;
}());
export { OverlayLayout };
