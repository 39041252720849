export var GateFiLangEnum;
(function (GateFiLangEnum) {
    GateFiLangEnum["en_US"] = "en_US";
    GateFiLangEnum["es_PE"] = "es_PE";
})(GateFiLangEnum || (GateFiLangEnum = {}));
export var GateFiDisplayModeEnum;
(function (GateFiDisplayModeEnum) {
    GateFiDisplayModeEnum["Overlay"] = "overlay";
    GateFiDisplayModeEnum["Embedded"] = "embedded";
})(GateFiDisplayModeEnum || (GateFiDisplayModeEnum = {}));
export var GateFiEventTypes;
(function (GateFiEventTypes) {
    GateFiEventTypes["onLoad"] = "onLoad";
    GateFiEventTypes["onInit"] = "onInit";
    GateFiEventTypes["onClose"] = "onClose";
    GateFiEventTypes["onSetThemeType"] = "onSetThemeType";
    GateFiEventTypes["_onRedirect"] = "onRedirect";
})(GateFiEventTypes || (GateFiEventTypes = {}));
