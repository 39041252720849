var PREFIX_LOG = "GateFiSDK:";
var ErrorService = (function () {
    function ErrorService() {
    }
    ErrorService.prototype.send = function (errorMessage) {
        console.error(PREFIX_LOG, errorMessage);
    };
    return ErrorService;
}());
export var errorService = new ErrorService();
