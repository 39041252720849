import { domControlService } from "../services/DOMControlService";
var EmbeddedLayout = (function () {
    function EmbeddedLayout() {
        this.widget = null;
    }
    EmbeddedLayout.prototype.create = function (baseUrl, nodeSelector) {
        this.widget = domControlService.createWidget(baseUrl);
        var container = document.querySelector(nodeSelector);
        container.appendChild(this.widget);
    };
    EmbeddedLayout.prototype.getContentWindow = function () {
        var _a;
        return ((_a = this.widget) === null || _a === void 0 ? void 0 : _a.contentWindow) || null;
    };
    EmbeddedLayout.prototype.destroy = function () {
        var _a;
        (_a = this.widget) === null || _a === void 0 ? void 0 : _a.remove();
    };
    return EmbeddedLayout;
}());
export { EmbeddedLayout };
